import React, { Component } from "react";
import CIcon from "@coreui/icons-react";
import {
  // cilBell,
  // cilCalculator,
  // cilChartPie,
  // cilCursor,
  // cilDescription,
  // cilDrop,
  // cilNotes,
  // cilPencil,
  // cilPuzzle,
  // cilSpeedometer,
  // cilStar,
  cilLocationPin,
  cilPeople,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  /* {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  }, */
  {
    component: CNavTitle,
    name: "Election Panel",
  },
  {
    component: CNavItem,
    name: " Properties (Channels)",
    to: "/election/properties",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Election Types",
    to: "/election/types",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Result Status",
    to: "/election/result-status",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Location",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Countries",
        to: "/election/countries",
      },
      {
        component: CNavItem,
        name: "States",
        to: "/election/states",
      },
      {
        component: CNavItem,
        name: "Districts",
        to: "/election/districts",
      },
      {
        component: CNavItem,
        name: "Cities",
        to: "/election/cities",
      },
      {
        component: CNavItem,
        name: "Regions",
        to: "/election/regions",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Constituency",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    to: "/election/constituencies",
  },

  {
    component: CNavItem,
    name: "Parties",
    to: "/election/parties",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: "Candidate Result",
  //   to: "/election/candidate-result",
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: "Alliances",
    to: "/election/alliances",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Caste-Category",
    to: "/election/caste-category",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Candidates",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    to: "/election/candidates",
  },
  {
    component: CNavGroup,
    name: "Election",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Elections",
        to: "/elections",
      },
      {
        component: CNavItem,
        name: "Election States",
        to: "/election/election-state",
      },
      {
        component: CNavItem,
        name: "Election Constituencies",
        to: "/election/electionconstits",
      },

      {
        component: CNavItem,
        name: "Election Results",
        to: "/election/election-results",
      },
      {
        component: CNavItem,
        name: "Candidate Status",
        to: "/election/candidate-status",
      },
      {
        component: CNavItem,
        name: "Candidate Extension",
        to: "/election/candidate-extension",
      },
      {
        component: CNavItem,
        name: "Constituency Extensions",
        to: "/election/constituencies/extensions",
      },
      {
        component: CNavItem,
        name: "Alliance Parties",
        to: "/election/alliances-parties",
      },
    ],
  },
];

export default _nav;
